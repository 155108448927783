<template>
  <div>
    <section class="white">
      <v-container>
        <v-row class="pb-5 pt-5">
          <v-col
            cols="10"
            offset="1"
            style="font-family:'Kanit'!important"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <h2
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
                class="mb-2"
                style="font-family:'Kanit'!important"
              >
                PRACE BADAWCZ0 - ROZWOJOWE
              </h2>
              <p
                class="subtitle-1 text-justify mb-1"
                style="font-family:'Kanit'!important"
              >
                ENERGETYKA ZAWODOWA
              </p>
            </v-col>
            <v-container class="d-inline-flex">
              <v-col cols="12">
                <v-card
                  outlined
                  tile
                  class="mb-5 centered text-justify"
                >
                  <!-- <v-card-title>
                    SAFE INVESTMENTS FOR THE TIMES
                  </v-card-title> -->
                  <v-card-text>
                    <p>
                      Projekty badawczo-rozwojowe stanowią istotną część działalności ENERGETYKI WAGON. Wspierają one przede wszystkim rozwój energetyki oraz produkcji i zarządzania odnawialnymi źródłami energii. Opracowujemy i dążymy do wdrożenia innowacyjnych rozwiązań w zakresie szeroko pojętej elektroenergetyki. Nasze prace i działania B+R skupiają się w ostatnim czasie głównie wokół procesu magazynowania energii. W szczególności badamy możliwości wykorzystania magazynów energii do stabilizacji sieci oraz analizujemy wykorzystanie rozproszonych magazynów energii do zapewnienia bezpieczenstwa energetycznego kraju. W centrum naszego zainteresowania leżą również analizy systemu elektroenergetycznego, w tym zaawansowane analizy parametrów sieci elektrycznej.
                    </p>
                    <p>Prace badawczo-rozwojowe będą prowadzone w ramach projektów dofinansowanych przez:
                      <ul>
                        <li>Narodowe Centrum Badań i Rozwoju</li>
                        <li>Polską Agencję Rozwoju Przedsiębiorczości</li>
                        <li>Urząd Marszałkowski Województwa Wielkopolskiego</li>
                        <li>Urząd Marszałkowski Województwa Mazowieckiego</li>
                        <li>Agencję Rozwoju Przemysłu</li>
                        <li>Ministerstwa RP</li>
                      </ul>
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<style scoped>
  .pure-border {
    border-left: 3px solid black;
  }
  .background-light-grey {
    background-color: #f1f1f1;
  }
</style>

<script>
  export default {
    data: () => ({
      highlights: [
        'Projekty i architektura',
        'Wykończenia wnętrz',
        'Inwestor zastępczy',
        'Wykonawstwo i nadzory',
        'Marketing',
        'Sprzedaż'
      ],
      experiences: [
        ['mdi-vuejs', '75', 'Vue'],
        ['mdi-vuetify', '100', 'Vuetify'],
        ['mdi-aws', '67', 'AWS'],
        ['mdi-server-security', '95', 'Security']
      ]
    })
  }
</script>
